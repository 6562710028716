import React from 'react';
import { Route, Redirect } from 'react-router-dom';
const DefaultLayout = React.lazy(() => import('../containers/DefaultLayout'));


export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props, ref) => (
        sessionStorage.getItem('isLoggedin')
        ? 
        <DefaultLayout></DefaultLayout>
        // <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )} 
    />
)